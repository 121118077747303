import React, { createContext, useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase'; // adjust this path as needed
import { API_GATEWAY_URL } from '../../config';

export const UserContext = createContext({
  user: null,
  loading: true,
});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // add loading state
  const [chatReply, setChatReply] = useState('');
  const [chatSourceDocumets, setChatSourceDocuments] = useState([]);
  const [notification, setNotification] = useState({ message: '', status: 'info' });

  // console.log('UserProvider user:', user);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setLoading(false); // set loading to false after receiving user data
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const askKnowledgeBase = async (query) => {
    try {
      const idToken = await user.getIdToken();

      const response = await fetch(`${API_GATEWAY_URL}/chat_kb?token=${idToken}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({query: query})
      });

      if (response.ok) {
        const data = await response.json();
        const reply = data.reply;
        setChatReply(reply);
        setChatSourceDocuments(data.source_documents);
      } else {
        // Error uploading file
        setNotification({ message: 'Error getting reply', status: 'error' });
      }
    } catch (error) {
      console.error('Error getting reply:', error);
      setNotification({ message: 'Error getting reply', status: 'error' });

    }
  };

  const cleanChat = async () => {
    try {
      const idToken = await user.getIdToken();

      const response = await fetch(`${API_GATEWAY_URL}/cleanChat?token=${idToken}`, {
        method: 'DELETE'
      });

      if (response.ok) {
        const data = await response.json();
        setNotification({ message: data.message, status: 'success'})
      } else {
        // Error uploading file
        setNotification({ message: 'Error starting new chat', status: 'error' });
      }
    } catch (error) {
      console.error('Error starting new chat:', error);
      setNotification({ message: 'Error starting new chat', status: 'error' });
    }

  }

  const fetchFilesFromPath = async (path) => {
    try {
        const idToken = await user.getIdToken();

        const response = await fetch(`${API_GATEWAY_URL}/browse-files?token=${idToken}&path=${path}`);
        if (response.ok) {
            const data = await response.json();
            return data.items || {};
        }
        throw new Error("Failed to fetch data");
    } catch (error) {
        console.error(error);
        return [];
    }
  };

  return (
    <UserContext.Provider value={{ user,cleanChat, loading,  askKnowledgeBase, chatReply, setChatReply, notification, fetchFilesFromPath, setNotification, chatSourceDocumets, setChatSourceDocuments}}>  
      {children}
    </UserContext.Provider>
  );
};
