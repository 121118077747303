import { initializeApp } from 'firebase/app';
import { getAuth, signOut, GoogleAuthProvider, signInWithPopup,} from 'firebase/auth';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBMAX5wTz9D3XEOWpxLPIfu9NWBCxsdIt8",
    authDomain: "cerebrobase-8882a.firebaseapp.com",
    projectId: "cerebrobase-8882a",
    storageBucket: "cerebrobase-8882a.appspot.com",
    messagingSenderId: "967345702501",
    appId: "1:967345702501:web:8f13bf5f8d6d97881fe88c",
    measurementId: "G-LZ54HDL1QX"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();

const signInWithGoogle = () => {
    console.log('signing in with google');
    const provider = new GoogleAuthProvider()
    signInWithPopup(auth, provider)
};

const logout = () => {
    signOut(auth).then(() => {
        console.log('User signed out');
    }).catch((error) => {
        console.error('Error signing out: ', error);
    });
};

export { app, auth, signInWithGoogle, logout };
