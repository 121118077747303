// gatsby-browser.js
import 'aframe';
import 'aframe-react';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './src/theme';
import './src/styles/main.css'; // Import the global CSS file
import { UserProvider } from './src/context/UserContext';
import { pdfjs } from 'react-pdf';
import pdfWorker from 'pdfjs-dist/webpack';
// gatsby-browser.js



pdfjs.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js';


export const wrapRootElement = ({ element }) => {
  return (
    <UserProvider>

      <ThemeProvider theme={theme}>
        {element}
      </ThemeProvider>
    </UserProvider>
  );
};
// gatsby-browser.js